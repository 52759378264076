<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="dialogPartidas.estatus"
    persistent
    max-width="1000"
  >
    <v-card>
    	<v-card-title primary-title>
    	  Multiples partidas
    	</v-card-title>
    	<v-card-text>

    		<upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" class="mr-2"/>

        <v-data-table
        	v-if="verTabla"
			    :headers="headers"
			    :items="detalles"
			    dense
			    class="elevation-0 mt-4"
			  >

			    <template v-slot:item.id_plantel="{ item }">
			      <span>{{ planteles.find( el=> el.id_plantel == item.id_plantel ).plantel }}</span>
			    </template>

			    <template v-slot:item.id_elemento_requisicion="{ item }">
			      <span>{{ elementos.find( el=> el.id_elemento_mantenimiento == item.id_elemento_requisicion ).elemento_mantenimiento }}</span>
			    </template>

			  </v-data-table>

    	</v-card-text>

    	<v-card-actions>

		    <v-btn tile dark small @click="dialogPartidas.estatus = false" color="black">
		    	<v-icon small left >mdi-close</v-icon>
		    	cancelar
		    </v-btn>

		    <v-spacer></v-spacer>

		    <v-btn tile dark small @click="$emit('cargarPartidaMultiple', detalles)" color="primary" v-if="detalles.length">
		    	<v-icon small left >mdi-upload</v-icon>
		    	Cargar
		    </v-btn>

    	</v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>
  </v-dialog>
</template>


<script>
import axios from 'axios';

	import { mapGetters } from 'vuex'


	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores       from '@/mixins/validarErrores'
  import UploadExcelComponent from '@/components/UploadExcel.vue'


	export default {
		props:[
			'dialogPartidas'
	  ],

  	components:{
      Alerta,
      carga,
      UploadExcelComponent
    },

    mixins: [ validarErrores ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: 'Elemento Compra' , value: 'id_elemento_requisicion' },
        { text: 'Concepto'        , value: 'concepto'                },
        { text: 'Plantel'         , value: 'id_plantel'              },
        { text: 'Cantidad'        , value: 'cantidad'                },
        { text: 'Costo Unit.'     , value: 'costo_unitario'          },
        { text: 'Total'           , value: 'costo_total'             },
        { text: 'Actions'         , value: 'actions', sortable: false },
      ],

      ciclos: [],
      ciclo: null,
      comentarios:'',

      opVisualizacion: 1,

      editedIndex: -1,

      editedItem: {
        id_elemento_requisicion: 0, 
		    id_plantel:              0, 
		    concepto:                '', 
		    cantidad:                0, 
		    costo_unitario:          0,
		    costo_total:             0,
		    id_empleado:             0, 
    		detalles:[]
      },

      defaultItem: {
        id_elemento_requisicion: 0, 
		    id_plantel:              0, 
		    concepto:                '', 
		    cantidad:                0, 
		    costo_unitario:          0,
		    costo_total:             0,
		    id_empleado:             0, 
    		detalles:[]
      },

      detalles:[],
      empleados:[],
      elementos:[],
      planteles:[],
      verTabla: true,

      id_usuario_solicita:'',
      id_sucursal_solicita:'',
      id_requisicion_compra_estatus:'',
      id_usuario_ultimo_cambio:'',

      id_usuario_compra: 28,
      tipo_requisicion:1,
      id_ciclo:0,
      comentarios:'',
      idrequisicion_compra : null,
      elementos:[],
      planteles:[],

    }),

    computed: {
    	...mapGetters('login', ['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
    },

    async created () {
    	await this.getPlanteles()
      await this.getElementosCompras()
    },

    methods: {

    	getPlanteles () {
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getElementosCompras () {
        this.elementos = []
        return this.$http.get('consultar.elemtos.compra').then(response=>{
        	this.elementos = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      beforeUpload(file) {
	      const isLt1M = file.size / 1024 / 1024 < 1
	      if (isLt1M) {
	        return true
	      }
	      this.$message({
	        message: 'Please do not upload files larger than 1m in size.',
	        type: 'warning'
	      })

	      return false
	    },

	    handleSuccess({results, header }) {
	      this.obtenerDatos(results);
	    },

	    obtenerDatos (results){
	    	console.log( results )
	      var me = this
	      let Temporal  =  results.map(obtenerValores)
	      let value2 = {}
	      function obtenerValores(value, index, array) {
	        
	        if (value['id_elemento_requisicion'] !== null ) {
	          value.id_elemento_requisicion = value['id_elemento_requisicion']
	        }

	        if (value['id_plantel'] !== null ) {
	          value.id_plantel = value['id_plantel']
	        }  

	        if (value['concepto'] !== null ) {
	          value.concepto = value['concepto']
	        }  

	        if (value['cantidad'] !== null ) {
	          value.cantidad = value['cantidad']
	        }  

	        if (value['costo_unitario'] !== null ) {
	          value.costo_unitario = value['costo_unitario']
	        }  

	        if (value['costo_total'] !== null ) {
	          value.costo_total = value['costo_total']
	        }  

	        value.id_empleado = 0

	        return value 
	      }

	      this.detalles = Temporal
	    },

    },
  }
</script>