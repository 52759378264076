import Vue from 'vue'
import { mapGetters,mapActions } from 'vuex';

//camara
import { Camera, CameraResultType } from '@capacitor/camera';
import { defineCustomElements }     from '@ionic/pwa-elements/loader';
import { Clipboard }                from '@capacitor/clipboard';
defineCustomElements(window);

const axios            = require('axios');


export default {
	methods: {

    cargarArchivo(){
      if(!this.archivos){ return }
      //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
      this.procesarImagen2( )
      this.getBase64(this.archivos)
    },

    getBase64(file) {
      var me = this
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        me.vistaPrevia = {
          url: reader.result, 
          image_name: file.name
        }
      };
    },


    procesarImagen2( ){
      this.cargar = true
      this.isPracticaja = false
      // Creando los parametros
      var formData = new FormData();
      formData.append("file"             , this.archivos );
      formData.append("language"         , "spa" );
      formData.append("apikey"           , "K84790465788957" ); /* K88754805388957    K84790465788957 */ 
      formData.append("isOverlayRequired", false );
      formData.append("detectOrientation", true );
      formData.append("scale"            , true );
      formData.append("isTable"          , true );
      formData.append("OCREngine"        , 5 );

      //Send OCR Parsing request asynchronously
      axios({
        url: 'https://api.ocr.space/parse/image',
        data: formData,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST'
      }).then( ocrParsedResult => {
        //Get the parsed results, exit code and error message and details
        var parsedResults                = ocrParsedResult.data["ParsedResults"];
        var ocrExitCode                  = ocrParsedResult.data["OCRExitCode"];
        var isErroredOnProcessing        = ocrParsedResult.data["IsErroredOnProcessing"];
        var errorMessage                 = ocrParsedResult.data["ErrorMessage"];
        var errorDetails                 = ocrParsedResult.data["ErrorDetails"];
        var processingTimeInMilliseconds = ocrParsedResult.data["ProcessingTimeInMilliseconds"];

        //If we have got parsed results, then loop over the results to do something
        if (parsedResults!= null) {
          // Guardamos las palabras
          let palabras        = parsedResults[0]['TextOverlay']['Lines']
          this.texto_completo = parsedResults[0]['ParsedText']

          let plantel = 0
          console.log( palabras )

          // RECIBOS DE ZOOM
          this.isZoom  = palabras.find( el => el.LineText.match('Zoom Video Commun'))
          
          // ES ZOOM, ahora hay que ver que salón es
          if( this.isZoom ){

          	// Buscamos el salon de fast
          	const isFAST  = palabras.find( el => el.LineText.match('fastenglish.com.mx'))

          	// si se encuentra, se va para el plantel de FAST si no, es inbi
          	plantel = isFAST ? 17 : 16


          }

          // Validar si el recibo es de agua y drenaje
          this.isReciboAgua  = palabras.find( el => el.LineText.match('Agua y Drenaje'))

          // Validar si el recibo es de Telefono
          this.isTelmex  = palabras.find( el => el.LineText.match('TELMEX'))

          // Validar si el es de IONOS
          this.isIonos  = palabras.find( el => el.LineText.match('IONOS Inc.'))

          // Validar si el recibo de LUZ
          this.isLuz  = palabras.find( el => el.LineText.match('Electricidad'))

          // Validar is Publicidad
          this.isMeta  = palabras.find( el => el.LineText.match('Meta'))

          // Validar si es de DIDI
          this.isDidi  = palabras.find( el => el.LineText.match('Duda sobre la tarifa'))

          // Validar si es de REnta
          this.isRenta  = palabras.find( el => el.LineText.match('Yo, recibí'))

          // Validar si es de Saldo 
          this.isSaldo  = palabras.find( el => el.LineText.match('Recargas y paquetes') )

          console.log( this.isZoom, this.isReciboAgua, this.isTelmex, this.isIonos, this.isLuz, this.isMeta, this.isDidi, this.isRenta, this.isSaldo )


          this.categoriaRecibo = 0

          if( this.isZoom || this.isIonos ){ this.categoriaRecibo = 7 }

          if( this.isReciboAgua || this.isTelmex || this.isLuz || this.isSaldo ){ this.categoriaRecibo = 3 }

          if( this.isRenta ){ this.categoriaRecibo = 2 }

          if( this.isMeta ){ this.categoriaRecibo = 4 }

          if( this.isDidi ){ this.categoriaRecibo = 8 }

          if( this.isMeta ){ this.categoriaRecibo = 4 }

          alert( this.categoriaRecibo, this.tipo_requisicion )

          if( this.categoriaRecibo != this.tipo_requisicion ){
            
          }


          this.cargar = false
        }
      }).catch( error => { 
        // this.removeFile()
        this.cargar = false
        this.validarErrorDirecto('La imagen no se pudo leer, favor de comunicarse con sistemas')
      })
    },

    onChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      
      if (!files.length) {
        this.dragging = false;
        return;
      }
      
      this.createFile(files[0]);
    },

    createFile(file) {
      if (!file.type.match('image.*')) {
        alert('please select txt file');
        this.dragging = false;
        return;
      }
      
      if (file.size > 10000000) {
        alert('please check file size no over 5 MB.')
        this.dragging = false;
        return;
      }
      
      this.file = file;
      this.archivos = file;
      this.getBase64(this.archivos)
      this.procesarImagen2( )
      this.dragging = false;
    },

    removeFile() {
      this.total_pagado = 0
      this.vistaPrevia        = null
      this.archivos           = null
      this.file               = '';
      this.banco              = ''
      this.isSpin             = false
    },
  }
}